.FromAdd{
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  width: 70%;
  background-color: #fafafa;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1%;
}


.userProfile{
  width: 70%;
  background-color: #fafafa;
  height: 100vh;
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 1%;
}
@media(max-width: 900px){
  .FromAdd{
  width: 100%;
  }
  .userProfile{
    width: 100%;
  }
}