.HeaderContainer {
    width: 100%;
    height: 80px;
    
}

.Stack {
    padding: 10px;
}

/* .title {
    color: #001627;
} */