.loaderHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
.customLoader {
    width: 100px;
    height: 100px;
    --c: radial-gradient(farthest-side,#28A745 92%,#0000);
    background: 
      var(--c) 50%  0, 
      var(--c) 50%  100%, 
      var(--c) 100% 50%, 
      var(--c) 0    50%;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    animation: s8 1s infinite;
    position: relative;
  }
  .customLoader::before {    
    content:"";
    position: absolute;
    inset:0;
    margin: 6px;
    background:repeating-conic-gradient(#0000 0 35deg,#28A745 0 90deg);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 6px),#000 0);
    border-radius: 50%;
  }
  
  @keyframes s8 { 
    100%{transform: rotate(.5turn)}
  }