.profileWrapper{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    /* margin-left: 120px; */
    }
.title{
    font-size: 35px;
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
}
.infoWrapper {
    width: 80%;
    margin-left: 12px;
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
  }
  .avatarAndInfoWrapper{
    display: flex;
    /* border: 2px solid blue; */
    /* margin-left: 90px; */
    width: 100%;
    justify-content: center;
  }
  
  .userInfoPart {
    /* border: 1px solid blue; */
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
  
  .userInfoPart h3 {
    margin: 9px 0;
  }
.buttonWrapper{
  /* margin-left: 130px; */
  width: 70%;
  display: flex;
  justify-content: space-between;
  /* border: 2px solid purple; */
}
  @media(max-width: 900px){
    .profileWrapper{
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }
    .avatarAndInfoWrapper{
      flex-direction: column;
    }
    .infoWrapper 
        {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: flex-start;
    }
    .buttonWrapper{
      justify-content: space-between;
      width: 90%;
    }
  
}