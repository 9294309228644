@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* {
  font-family:  'Roboto', sans-serif, Geneva, Tahoma, sans-serif;
  text-decoration: none;
}

/* h1{
  font-size: 40px;
} */
/* h2{
  font-size: 32px;
}
h3{
  font-size: 24px;
}
h4{
  font-size:16px;
} */
body{
  background-color: #eee;
  color:#394452;
}
a{
  color: #394452;
}
:root{
  --primary-color: black;
}
::selection{
  background-color: #28a745;
  color: whitesmoke;
}