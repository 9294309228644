@media (max-width:540px) {
    .cardContainer{
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
}

@media (max-width:900px) {
    .details{
        display: none;
    }
}
