.Header{
    background-color: #222;
    display: flex;
    flex-wrap: nowrap;
    column-gap: 3px;
    justify-content: space-around;
    /* border: 3px solid blue; */
    z-index: 100;
    
    position: sticky;
    top: 0;
}
.headerWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center ;  /* very important*/
    padding-top: 0.6%;
    padding-bottom: 0.4%;
    background-color: #222;
    width: 80%;   
    z-index: 100;   
}
.aboutLink{
    color: #ebede9;
    font-weight: 900;
    margin-right: 30px;
    font-size: 18px;
}
.aboutLink:hover{
    color: hsl(134, 58%, 56%);
    cursor: pointer;   
}
.cardImg{
width: 100%;
height: 100%;
}
.imageHolder{
    width: 350px;
    height: 200px;
}
.logo{
    width: 10rem;
}
.logo:hover{
    cursor: pointer;
}
.navBar{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}
.AboutContent{
    min-height: 50vh;
    display: flex;
    padding: 100px;
    flex-wrap: wrap;
    justify-content: space-between;

}
.aboutImgHolder{
width: 35%;
height: 100%;
}
.aboutImg{
    width: 100%;
    height: 100%;
}
.aboutTextWrapper{
    width: 60%;
}
.aboutContentTitle{
    margin-bottom: 20px;
    font-size: 35px;
}
.AboutContentParagraph{
   line-height: 1.6rem;
   margin-bottom: 6px;
}
.card{
    display: flex;
    width: 60%;
    align-self: center;
    flex-wrap: nowrap;
    background-color: #fafafa;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin-top: 20px;
}
.infoWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-left: 20px;
}

.cardWrapper{
    display: flex;
    flex-direction: column;
    height: fit-content;
    min-height: 80vh;
}
.buttonWrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

}
.phoneButton{
    background-color: #28A745;
    color: whitesmoke;
    width: 100px;
    padding: 8px;
    border-radius: 10px;
    align-items: center;
    font-weight: 700;
    
}
.searchBarWrapper{
    width: 100%;
    height: 300px;
    display: flex; 
    align-items: center;
    justify-content: center;
    background-image:  url(https://i.ibb.co/xDxNGCy/wepik-export-20230530083233-Qd7o.png),url(../../assests/Illustration/wepik-export-20230530083233Qd7o.png);  
    background-size: cover;
}

.aboutTitle{
    font-size: 40px;
    color: #EBEDE9;
}
.quotationColor{
    color: #17cb41;
}
.titleAboveSearchBar{
    color:#EBEDE9;
    margin-bottom: 10px;
    font-size: clamp(1rem, 2vw, 3rem);
    text-align: center;
}
.emailButton{
    border: 2px solid #28A745;
    padding: 7px;
    width: 100px;
    color: #28A745;
    border-radius: 10px;
    font-weight: 700;
    margin-left: 10px;
}
@media (max-width: 1300px){
    .aboutImgHolder{
        display: none;
    }
    .aboutTextWrapper{
        
        width: 100%;
    }
    
}
@media (max-width: 800px) {
    .aboutTextWrapper{
        width: 100%;

    }
    .aboutTextWrapper p:nth-child(1),.aboutTextWrapper p:nth-child(2) {
      display: none;
    }
    .aboutImgHolder{
        display: none;
    }
    .AboutContent{
        padding: 15px;
    }
  }
@media (max-width:440px) {
    .headerWrapper{
        width: 100%;
        justify-content: space-between;

    }
    .cardWrapper{
        display: flex;   
        flex-direction: column;
    }
    
    .card{
        width: 100%;
        margin-top: 9px;
    }
    .aboutLink{
        margin-right: 10px;
    }
    .logo{
        width: 100px;
    }   
}
